










































































import { Mixin_list } from "@/mixin";
import { api_meal, meal } from "@/http/meal";
import { Component } from "vue-property-decorator";
import Page from "@/components/page/index.vue";
import Table from "@/components/table/index.vue";

@Component({
  components: {
    Page,
    Table,
  },
})
export default class extends Mixin_list<meal>(api_meal.get_list) {
  filter = {
    id: "",
    phone: "",
    nickname: "",
    user_type: "",
    // create_time_before: "",
    // create_time_after: "",
    status: "",
  };
  resetFilter = {
    ...this.filter,
  };
  columns = [
    {
      label: "ID",
      prop: "id",
    },
    {
      label: "会员卡名称",
      prop: "name",
    },
    {
      label: "充值金额",
      prop: "amount",
    },
    {
      label: "优惠价",
      prop: "labels",
    },
    {
      label: "赠品",
      prop: "gifts",
    },
    {
      label: "有效期",
      prop: "lifespan",
      slot: "lifespan",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "更新时间",
      prop: "update_time",
    },
    {
      label: "操作",
      prop: "",
      width: 300,
      slot: "operation",
    },
  ];
  // 删除
  async del(id: number) {
    await api_meal.del_one(id);
    this.get_list();
  }
  click_cell() {
    // return this.$router.push(`/user/detail/?id=${row.id}`);
  }
  getDiscount(value:any){
    const discount = JSON.parse(value)
    return discount
  }
}
